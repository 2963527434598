import React from "react";
import { Link } from "gatsby";

const PostLink = ({ post }) => (
  <article className="card ">
    <Link to={post.frontmatter.path}>
      <img
        src={post.frontmatter.thumbnail || "/assets/default.jpg"}
        alt={post.frontmatter.title}
      />
    </Link>
    <header>
      <h2 className="post-title">
        <Link to={post.frontmatter.path} className="post-link">
          {post.frontmatter.title}
        </Link>
      </h2>
      <div className="post-meta cursor-pointer">
        <Link to={post.frontmatter.path} className="post-meta-link">
          {post.frontmatter.description}
        </Link>
      </div>
    </header>
  </article>
);
export default PostLink;
