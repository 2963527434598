import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PostLink from "../components/post-link";
import BlogSearch from "../components/search-blog";
import SEO from "../components/seo";

const limit = 15;

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState("");

  const totalPages = Math.ceil(edges.length / limit);

  let blogPosts = edges;
  if (searchKey) {
    blogPosts = edges.filter(post =>
      post.node.frontmatter.title
        .toLowerCase()
        .includes(searchKey.toLowerCase())
    );
  }

  const visiblePosts = blogPosts.slice(0, limit * pageNumber);

  const Posts = visiblePosts.map(edge => (
    <PostLink key={edge.node.id} post={edge.node} />
  ));

  const previous = () => {
    setPageNumber(pageNumber => pageNumber - 1);
  };

  const next = () => {
    setPageNumber(pageNumber => pageNumber + 1);
  };

  const handleSearchChange = e => {
    setSearchKey(e.target.value);
  };

  return (
    <Layout>
      <SEO
        title={`Blog | ${site.siteMetadata.title}`}
        description={site.siteMetadata.description}
        image={site.siteMetadata.image}
      />
      <div className="blogs-header">
        <BlogSearch searchKey={searchKey} handleChange={handleSearchChange} />
      </div>
      <div className="grids">{Posts}</div>
      <div className="next-prev-button">
        {pageNumber > 1 ? (
          <div className="prev">
            <button className="button" onClick={previous}>
              PREVIOUS
            </button>
          </div>
        ) : null}
        {pageNumber < totalPages ? (
          <div className="next">
            <button className="button" onClick={next}>
              NEXT
            </button>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default IndexPage;
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
        image
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { path: { ne: "/about", regex: "/^(?!\/bipin-admin)/" } } } # Get all data without about page as it has its own page
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            description
          }
        }
      }
    }
  }
`;
