import React from "react";

export default function SearchPost({ searchKey, handleChange }) {
  return (
    <input
      type="text"
      placeholder="Search Blogposts"
      value={searchKey}
      onChange={handleChange}
    />
  );
}
